import infoWhite from '../../../assets/icons/infoWhite.svg'
import React, { useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import styles from './ProductInfoPopover.module.scss'
import { IProduct } from '../../../types/product'

interface ProductInfoPopoverProps {
    className?: string
    appName?: string
    currentProduct: IProduct
}

export const ProductInfoPopover = (props: ProductInfoPopoverProps) => {
    const { className, appName, currentProduct } = props
    const [show, setShow] = useState(false)
    const target = useRef(null)

    const handleOnMouseEnter = (): void => {
        setShow(true)
    }
    const handleOnMouseLeave = (): void => {
        setShow(false)
    }

    return (
        <>
            <span
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                className={`${className} pointer`}
                ref={target}
            >
                <img
                    src={infoWhite}
                    alt="product info"
                />
            </span>
            <Overlay
                target={target.current}
                show={show}
                placement="right"
            >
                <Popover className="p-2">
                    <table>
                        <tbody className="align-top">
                            <tr>
                                <td className={styles.infoKeyCell}>
                                    <strong>App name: </strong>
                                </td>
                                <td
                                    className={`ps-2 ${styles.infoContentCell}`}
                                >
                                    {appName}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {' '}
                                    <strong>Path: </strong>
                                </td>
                                <td
                                    className={`ps-2 ${styles.infoContentCell}`}
                                >
                                    {currentProduct.path}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.infoKeyCell}>
                                    <strong>Product IP: </strong>
                                </td>
                                <td
                                    className={`ps-2 ${styles.infoContentCell}`}
                                >
                                    {currentProduct.ip}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Popover>
            </Overlay>
        </>
    )
}
