import { TimeFormats } from './types'

export const getTimeSep = (timeSchema: string): string | undefined => {
    const timeSepReg = /[h,H]{2}.[M,m]{2}/g
    if (timeSchema.match(timeSepReg)) {
        const hoursPosition = timeSchema.search(timeSepReg)
        return timeSchema[hoursPosition + 2]
    }
}
export const getDateSep = (timeSchema: string): string | undefined => {
    const dateSepReg = /^[Y,y]{4}.[M,m]{2}/g
    if (timeSchema.match(dateSepReg)) {
        return timeSchema[4]
    }
}

export const getTimeFormat = (timeSchema: string): string =>{
  const reg = /(yyyy|MM|dd|hh|mm|ss|fff)/g
  return timeSchema.toLowerCase().match(reg)
      ? timeSchema
      : 'yyyy/mm/dd hh:mm:ss'
}

const getWithZero = (val: any): string =>
  Number(val) + 1 > 10 ? val : `0${val}`

export const setTimeFormat = (timeSchema: string) => {
  const formatCheck = getTimeFormat(timeSchema)

  if (formatCheck.length > 20 && formatCheck.includes('fff')) {
    return TimeFormats.FULL_DATE_FULL_TIME
  }
  if (formatCheck.length > 17 && formatCheck.includes('ss')) {
    return TimeFormats.DATE_HOURS_MINUTES_SECONDS
  }
  if (formatCheck.length > 14 && formatCheck.includes('mm')) {
    return TimeFormats.DATE_HOURS_MINUTES
  }
  if (formatCheck.length > 12 && formatCheck.includes('hh')) {
    return TimeFormats.DATE_AND_HOURS
  }
  if (formatCheck.length > 9 && formatCheck.includes('fff')) {
    return TimeFormats.FULL_TIME
  }
  if (formatCheck.length > 6 && formatCheck.includes('ss')) {
    return TimeFormats.HOURS_MINUTES_SECONDS
  }
  if (formatCheck.length > 3 && formatCheck.match(/[h,H]{2}.[M,m]{2}/g)) {
    return TimeFormats.HOURS_MINUTES
  }
  if (formatCheck.length < 3 && formatCheck.includes('hh')) {
    return TimeFormats.HOURS
  }
  if (formatCheck.length === 10 && formatCheck.includes('YYYY')) {
    return TimeFormats.DATE
  }
  return TimeFormats.UNSUPPORTED_FORMAT
}

export const transformDate = (
    timeFormat: string,
    date: Date,
    timeSchema: string,
) => {
  const dateSep = getDateSep(timeSchema)
  const timeSep = getTimeSep(timeSchema)
  const current = new Date(date)
  const year = current.getFullYear()
  const month =
    Number(current.getMonth()) + 1 > 9
      ? Number(current.getMonth()) + 1
      : `0${Number(current.getMonth()) + 1}`
  const day = getWithZero(current.getDate())
  const hours = getWithZero(current.getHours())
  const minutes = getWithZero(current.getMinutes())
  const seconds = getWithZero(current.getSeconds())
  const fff = current.getMilliseconds()

  if (!date) return null

  switch (timeFormat) {
    case TimeFormats.FULL_DATE_FULL_TIME:
      return `${year}${dateSep}${month}${dateSep}${day} ${hours}${timeSep}${minutes}${timeSep}${seconds}.${fff}`
    case TimeFormats.DATE_HOURS_MINUTES_SECONDS:
      return `${year}${dateSep}${month}${dateSep}${day} ${hours}${timeSep}${minutes}${timeSep}${seconds}`
    case TimeFormats.DATE_HOURS_MINUTES:
      return `${year}${dateSep}${month}${dateSep}${day} ${hours}${timeSep}${minutes}`
    case TimeFormats.DATE_AND_HOURS:
      return `${year}${dateSep}${month}${dateSep}${day} ${hours}`
    case TimeFormats.FULL_TIME:
      return `${hours}${timeSep}${minutes}${timeSep}${seconds}.${fff}`
    case TimeFormats.HOURS_MINUTES_SECONDS:
      return `${hours}${timeSep}${minutes}${timeSep}${seconds}`
    case TimeFormats.HOURS_MINUTES:
      return `${hours}${timeSep}${minutes}`
    case TimeFormats.HOURS:
      return `${hours}`
    default :
      return `${year}${dateSep}${month}${dateSep}${day}`
  }
}
