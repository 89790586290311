import styles from './ActionTab.module.scss'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import React from 'react'
import { convertToSentenceCase } from '../../../utils/convertToSentenceCase'

interface ActionTabProps {
    className?: string
    guid: string
    actionName: string
    onActionTabClick: (actionName: string) => void
    activeActionName: string
}

export const ActionTab = (props: ActionTabProps) => {
    const { guid, actionName, onActionTabClick, activeActionName } = props
    const productActions =
        useTypedSelector((store) => store.actions.productsActions[guid]) ?? {}

    const actionNameModified = convertToSentenceCase(actionName)
    const currentAction = productActions[actionName] ?? {}
    const isActive = activeActionName === actionName
    const handleActionClick = () => onActionTabClick(actionName)

    return (
        <div
            className={`${styles.actionTab} ${isActive && styles.active}` }
            onClick={handleActionClick}
        >
            <strong>{actionNameModified}</strong>
            {!!currentAction.description && (
                <p className={styles.description}>
                    {currentAction.description}
                </p>
            )}
        </div>
    )
}
