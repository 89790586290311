import {
    checkboxInput,
    dateTimePicker,
    doubleInput,
    integerInput,
    passwordInput,
    selectInput,
    stringInput,
} from '../inputs/controls'
import { getEmptyData } from '../../utils/InitData'
import { inputValidationCheck } from '../../validators/inputValidators/inputValidators'
import { numberInputErrors } from '../../utils/validationErrors/numberInputErrors'
import { stringInputErrors } from '../../utils/validationErrors/stringInputErrors'
import { secretInputErrors } from '../../utils/validationErrors/secretInputErrors'
import { ModelTypes } from './types'
import { datePickerErrors } from '../../utils/validationErrors/datePickerErrors'
import { enumErrors } from '../../utils/validationErrors/enumErrors'

interface IInputCall {
    type: string
    name: string
    isLabelHidden: boolean
    schema: any
    data?: any
    secretSchema?: any
    state?: any
    isFeedbackFixed?: boolean
    placeholder?: string
    className?: string
    onEnter?: Function
    onBlur?: Function
}

export function inputCall(params: IInputCall) {
  const {
    type,
    name,
    isLabelHidden,
    data,
    schema,
    secretSchema,
    state,
    isFeedbackFixed,
    placeholder,
    className,
    onEnter,
    onBlur,
  } = params

  switch (type) {
    case ModelTypes.INTEGER:
      return integerInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: numberInputErrors(state?.[name], schema),
        validationType: data?.validationType,
        isRequired: schema?.is_required,
        isFeedbackFixed,
        placeholder,
        className,
        onEnter,
        onBlur,
      })
    case ModelTypes.STRING:
      return stringInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: stringInputErrors(state?.[name], schema),
        isRequired: schema?.is_required,
        isFeedbackFixed,
        placeholder,
        className,
        onEnter,
        onBlur,
      })
    case ModelTypes.SECRET:
      return passwordInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: secretInputErrors(state?.[name], schema),
        validationSchema: inputValidationCheck(
          secretSchema?.type,
          secretSchema
        ),
        isRequired: schema?.is_required,
        isFeedbackFixed,
        placeholder,
        className,
      })
    case ModelTypes.NUMBER:
      /* return numberInput({
        name,
        isLabelHidden,
        isColumn: data.isColumn,
        description: data.tooltipText,
        label: data.label,
        errMsg: numberInputErrors(state?.[name], schema),
        isRequired: schema.is_required,
        isFeedbackFixed,
        className,
        onEnter,
        onBlur,
      }) */
    case ModelTypes.DOUBLE:
      return doubleInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: numberInputErrors(state?.[name], schema),
        validationType: data?.validationType,
        precision: schema?.precision || 8,
        isRequired: schema?.is_required,
        isFeedbackFixed,
        placeholder,
        className,
        onEnter,
        onBlur,
      })
    case ModelTypes.ENUM:
      return selectInput({
        name,
        options: Object.keys(schema?.options || {}),
        selected:
          data?.selected || state?.[name] || schema?.default || Object.keys(schema?.options)[0],
        isColumn: data?.isColumn,
        className,
        label: data?.label,
        tooltipText: data?.tooltipText
      })
    case ModelTypes.TIME:
    case ModelTypes.DATETIME:
      return dateTimePicker(
          name,
          {
            timeFormat: schema?.format,
            timeSchema: schema?.format,
            isColumn: true,
            minDate: schema?.greater_or_equal || schema?.greater_than,
            maxDate: schema?.less_or_equal || schema?.less_than,
            description: data?.tooltipText,
            errMsg: datePickerErrors(state?.[name], schema)
          },
          schema?.is_required,
          isLabelHidden
      )
    case ModelTypes.BOOL:
      return checkboxInput(
        name,
        data?.tooltipText,
        data?.label,
        className ?? 'd-flex justify-content-center pb-1',
        false,
        undefined,
        undefined,
        schema?.default
      )
    case ModelTypes.FLAG:
      return checkboxInput(
        name,
        data?.tooltipText,
        data?.label,
        className ?? 'justify-content-center',
        false,
        'switch',
        undefined,
        schema?.default,
      )
    default:
      return stringInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: stringInputErrors(state?.[name], schema),
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        placeholder,
        className,
        onEnter,
        onBlur,
      })
  }
}

export const createEmptyDocument = (schema: any, name: string, data?: any) => {
  let init: any = data || {}

  if (schema?.children) {
    const keys = Object.keys(schema?.children || {})
    for (const item in keys) {
      const element = getEmptyData(schema?.children[keys[item]])
      init = { ...init, [keys[item]]: element }
    }
  }
  return init
}

export const getErrorsByType = (type: string, data: any, schema: any) => {
  switch (type) {
    case ModelTypes.STRING:
      return stringInputErrors(data, schema)
    case ModelTypes.INTEGER:
      return numberInputErrors(data, schema)
    case ModelTypes.DOUBLE:
    case ModelTypes.NUMBER:
      return numberInputErrors(data, schema)
    case ModelTypes.SECRET:
      return secretInputErrors(data, schema)
    case ModelTypes.TIME:
    case ModelTypes.DATETIME:
      return datePickerErrors(data, schema)
    case ModelTypes.DURATION:
      return numberInputErrors(data, schema)
    case ModelTypes.ENUM:
      return enumErrors(data, schema)
    default:
      return stringInputErrors(data, schema)
  }
}
