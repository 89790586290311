import { ActionsActionTypes, IActionsState } from '../../types/actionsTypes'

const initialState: IActionsState = {
    productsActions: {},
    actionsHistory: {},
    isLoading: false,
    error: '',
}

export const actionsReducer = (
    state: IActionsState = initialState,
    action: { type: string; payload: any }
): IActionsState => {
    const { type, payload } = action

    switch (type) {
        case ActionsActionTypes.GET_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productsActions: {
                    ...state.productsActions,
                    [payload.guid]: payload.actions,
                },
            }
        case ActionsActionTypes.GET_ACTIONS_HISTORY:
            return {
                ...state,
                isLoading: false,
                actionsHistory: {
                    ...state.actionsHistory,
                    [payload.guid]: payload.productActionsHistory,
                },
            }
        default:
            return state
    }
}
