export enum inputTypes {
  STRING = 'string',
  INTEGER = 'integer',
  SECRET = 'secret',
  ENUM = 'enum',
  NUMBER = 'number',
  DATETIME = 'datetime',
  TIME = 'time',
  DURATION = 'duration',
  DOUBLE = 'double',
  BLOCK_ARRAY = 'block_array',
  TEXT = 'text'
}
