import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { Badge } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import style from './NotificationsBtn.module.scss'
import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal, showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { User, UserPermissions } from '../../../entity/User'

interface NotificationsBtnProps {
    className?: string
}

export const NotificationsBtn = memo(function NotificationsBtn(
    props: NotificationsBtnProps
) {
    const { className } = props
    const dispatch = useDispatch()
    const user = new User()
    const { notifications } = useTypedSelector((store) => store.notifications)
    const { modalType } = useTypedSelector((store) => store.modal)

    const unreadNotifications = notifications.filter((notis) => notis.status === 'unread')
    const unreadNotificationsCount = unreadNotifications.length

    const handleClick = () => {
        if (!modalType) {
            dispatch(showModal(ModalTypes.NOTIFICATIONS_MODAL))
        } else {
            dispatch(hideModal())
        }
    }

    if (!user.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION)){
        return <></>
    }

    return (
        <button
            className={`${style.button} ${className}`}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faBell} />
            {!!unreadNotificationsCount && (
                <Badge
                    pill
                    bg="info"
                    className={style.customBadge}
                >
                    <span className="visually-hidden rounded">
                        Unread notifications
                    </span>
                </Badge>
            )}
        </button>
    )
})
