import { v4 as uuidv4 } from 'uuid'

export const updateElementIds = (configurationObj: any) => {
    const uniqueIds: Record<string, string> = {}

    const updateElementIdsHelper = (obj: any) => {
        if (obj !== undefined && obj !== null && typeof obj === 'object') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (key === 'element_id') {
                        const elementId = obj[key]
                        const isDuplicate = uniqueIds[elementId]
                        if (isDuplicate) {
                            obj[key] = uuidv4()
                        } else {
                            uniqueIds[elementId] = elementId
                        }
                    } else {
                        updateElementIdsHelper(obj[key])
                    }
                }
            }
        }
    }
    updateElementIdsHelper(configurationObj)
}
