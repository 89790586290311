import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import style from './Styles/index.module.scss'
import { withTooltip } from 't4b-core-frontend'

interface IDownloadBtn {
    action: any
    className?: string
    icon?: IconDefinition
    disabled?: boolean
    showTooltip?: boolean
    squareBtn?: boolean
}

export const DownloadBtn: FC<IDownloadBtn> = ({
  action,
  icon,
  disabled,
  className,
  showTooltip,
  squareBtn
}) => {

    const downloadBtn = (
        <button
            disabled={disabled || false}
            className={`${style.button} ${squareBtn && style.buttonSquare} ${className}`}
            onClick={action}
            type="button"
        >
            <FontAwesomeIcon icon={icon || faDownload} />
        </button>
    )

    if (showTooltip) {
        return withTooltip(downloadBtn, 'Download product logs', 'download', 'top')
    }

  return downloadBtn
}
