export enum ActionsActionTypes {
    GET_ACTIONS = 'GET_ACTIONS',
    GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR',
    GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS',
    GET_ACTIONS_HISTORY = 'GET_ACTION_HISTORY',
}

export interface IAction {
    description: string
    request_type: 'request' | 'custom_request'
    execution_mode: 'sync_mode' | 'async_mode'
    access_level: 'access_full' | 'access_restricted'
    request_schema: Record<string, unknown>
}

export type IActionsDict = Record<string /* Action name */, IAction>

export interface IActionHistory {
    action_id: string
    action_name: string
    product_id: string
    status: 'ongoing' | 'success' | 'failure'
    issue_time: string // 'YYYY-MM-DDThh:mm:ss'
    client_payload: Record<string, unknown>
    has_result: boolean
    result?: {
        filename: string,
        mime_type: string
    }
    error?: string | undefined
}

export interface IActionsState {
    productsActions: Record<string /* guid */, IActionsDict>
    actionsHistory: Record<string /* guid */, IActionHistory[]>
    isLoading: boolean
    error: string
}
