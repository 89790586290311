import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { getLabel } from '../../../validators'
import { RunTestsButton } from '../RunTestsButton'
import style from './Styles/index.module.scss'
import { IProduct } from '../../../types/product'
import { ProductInfoPopover } from '../../overlays/ProductInfoPopover/ProductInfoPopover'
import { UploadDownloadConfig } from '../../overlays/UploadDownloadConfig/UploadDownloadConfig'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { RightBarActionType } from '../../../types/rightbar'
import { ActionsBar } from '../../ActionsBar/ActionsBar'
import { LogsDropdown } from '../../overlays/LogsDropdown/LogsDropdown'
import ProductCardProductStatus from '../../ProductCard/components/ProductCardProductStatus'
import { User, UserPermissions } from '../../../entity/User'

interface IProductButtonsPanel {
    appName?: string
    title: string
    guid: string
    currentProduct: IProduct
}

const ProductButtonsPanel: FC<IProductButtonsPanel> = ({
    appName,
    title,
    guid,
    currentProduct,
}) => {
    const dispatch = useAppDispatch()
    const { productNameChangeCounter } = useTypedSelector(
        (state) => state.products
    )
    const [productName, setProductName] = useState<string>('')
    const currentProductActions = useTypedSelector(
        (store) => store.actions.productsActions[guid]
    )
    const { status, type } = currentProduct

    const user = new User()
    const isWL = user.permissions.includes(
        UserPermissions.CONFIGURATION_ADMINISTRATION
    )
    const isActionsDisabled = !Object.keys(currentProductActions ?? {}).length

    useMemo(() => {
        const raw =
            localStorage.getItem(`${guid}-productName`) || getLabel(title)
        setProductName(raw)
    }, []) // eslint-disable-line

    useMemo(() => {
        const raw =
            localStorage.getItem(`${guid}-productName`) || getLabel(title)
        setProductName(raw)
    }, [productNameChangeCounter]) // eslint-disable-line

    const handleShowActions = () => {
        dispatch({
            type: RightBarActionType.HALF_SCREEN,
            payload: {
                rightBarItem: <ActionsBar guid={guid} />,
            },
        })
    }

    return (
        <div className={`d-flex px-3 align-items-center ${style.container}`}>
            <div className="d-flex gap-2">
                <h1
                    className={`${style.title} app-color-text mb-1 mt-2 text-capital word-break py-2`}
                >
                    {productName}
                </h1>
                <ProductInfoPopover
                    className="pt-3"
                    appName={appName}
                    currentProduct={currentProduct}
                />
            </div>
            <div className="ml-auto d-flex align-items-center gap-3">
                <ProductCardProductStatus
                    guid={guid}
                    status={status}
                    productType={type}
                />
                {isWL && (
                    <>
                        <div className="d-flex gap-2 mx-1">
                            <LogsDropdown guid={guid} />
                            <UploadDownloadConfig guid={guid} />
                        </div>
                    </>
                )}
                <button
                    type="button"
                    disabled={isActionsDisabled}
                    className={`app-btn-main ${!isActionsDisabled && 'app-btn-reset'} ${
                        isActionsDisabled && style.disabled
                    }`}
                    onClick={handleShowActions}
                >
                    <FormattedMessage id="actions" />
                </button>
            </div>
            <RunTestsButton guid={guid} />
        </div>
    )
}

export default React.memo(ProductButtonsPanel)
