import { IErrMsg } from '../../components/inputs/types'
import { IntegerSchemaValues } from '../../validators/inputValidators/utils/getIntegerSchema'

export const numberInputErrors = (
    state: any,
    schema: any
): IErrMsg | undefined => {
    if (schema.type === 'integer') {
        return integerErrors(state, schema)
    }
    if (schema.type === 'double' || schema.type === 'duration') {
        return doubleErrors(state, schema)
    }
    /* if (schema.type === 'number') {
        return numberErrors(state, schema)
    } */
}

const numberErrors =  (state: string, schema: any): IErrMsg | undefined => {
    if (state && !String(state).match(/^(-)?\d+(\.\d+)?$/)) {
        return { message: 'errors.validation.invalidValue'}
    }
    if (typeof schema.precision === 'undefined' &&
        String(state).split('.')?.[1]?.length > 8
    ){
        return { message: 'errors.validation.numberPrecision' }
    }
    if (schema.is_required && !state) {
        return { message: 'errors.validation.validValueRequired' }
    }
    if (
        typeof schema.greater_or_equal !== 'undefined' &&
        Number(state) < schema.greater_or_equal
    ) {
        return {
            message: 'errors.validation.greaterOrEqual',
            values: { greaterOrEqual: schema.greater_or_equal },
        }
    }
    if (
        typeof schema.greater_than !== 'undefined' &&
        Number(state) <= schema.greater_than
    ) {
        return {
            message: 'errors.validation.greaterThan',
            values: { greaterThan: schema.greater_than },
        }
    }
    if (
        typeof schema.less_or_equal !== 'undefined' &&
        Number(state) > schema.less_or_equal
    ) {
        return {
            message: 'errors.validation.lessOrEqual',
            values: { lessOrEqual: schema.less_or_equal },
        }
    }
    if (
        typeof schema.less_than !== 'undefined' &&
        state >= schema.less_than
    ) {
        return {
            message: 'errors.validation.lessThan',
            values: { lessThan: schema.less_than },
        }
    }
}

const doubleErrors = (state: any, schema: any): IErrMsg | undefined => {
  if (schema.is_required && !state) {
    return { message: 'errors.validation.fieldRequired' }
  }
  if (String(state).includes('e')) {
    return { message: 'errors.validation.numbersOnly' }
  }
  if (
    typeof schema.greater_or_equal !== 'undefined' &&
    Number(state) < schema.greater_or_equal
  ) {
    return {
      message: 'errors.validation.greaterOrEqual',
      values: { greaterOrEqual: schema.greater_or_equal },
    }
  }
  if (
    typeof schema.greater_than !== 'undefined' &&
    Number(state) <= schema.greater_than
  ) {
    return {
      message: 'errors.validation.greaterThan',
      values: { greaterThan: schema.greater_than },
    }
  }
  if (
    typeof schema.less_or_equal !== 'undefined' &&
    Number(state) > schema.less_or_equal
  ) {
    return {
      message: 'errors.validation.lessOrEqual',
      values: { lessOrEqual: schema.less_or_equal },
    }
  }
  if (
    typeof schema.less_than !== 'undefined' &&
    Number(state) >= schema.less_than
  ) {
    return {
      message: 'errors.validation.lessThan',
      values: { lessThan: schema.less_than },
    }
  }
  if (typeof schema.precision !== 'undefined' &&
      String(state).split('.')?.[1]?.length >= Number(schema.precision)
  ){
      return {
          message: 'errors.validation.precision',
          values: { precision: schema.precision },
      }
  }
  if (state) {
    return { message: 'Invalid value' }
  }
}

const integerErrors = (state: any, schema: any): IErrMsg | undefined => {
  if (schema.is_required && !state) {
    return { message: 'errors.validation.fieldRequired' }
  }
  if (String(state).includes('e')) {
    return { message: 'errors.validation.numbersOnly' }
  }

  if (state !== '' && Number.isInteger(Number(state))) {
      if (typeof schema.greater_or_equal !== 'undefined' && typeof schema.greater_than !== 'undefined') {
          const greaterOrEqual = Number(schema.greater_or_equal);
          const greaterThan = Number(schema.greater_than);
          const priorityValidator = greaterThan >= greaterOrEqual
              ? IntegerSchemaValues.GREATER_THAN
              : IntegerSchemaValues.GREATER_OR_EQUAL;

          if (priorityValidator === IntegerSchemaValues.GREATER_OR_EQUAL
              && BigInt(Number(state)) <= BigInt(schema.greater_or_equal)) {
              return {
                  message: 'errors.validation.greaterOrEqual',
                  values: { greaterOrEqual: schema.greater_or_equal },
              }
          }
          if (priorityValidator === IntegerSchemaValues.GREATER_THAN
              && BigInt(Number(state)) <= BigInt(schema.greater_than)) {
              return {
                  message: 'errors.validation.greaterThan',
                  values: { greaterThan: schema.greater_than },
              }
          }
      }

    if (
      typeof schema.greater_or_equal !== 'undefined' &&
      BigInt(Number(state)) <= BigInt(schema.greater_or_equal)
    ) {
      return {
        message: 'errors.validation.greaterOrEqual',
        values: { greaterOrEqual: schema.greater_or_equal },
      }
    }
    if (
      typeof schema.greater_than !== 'undefined' &&
      BigInt(Number(state)) <= BigInt(schema.greater_than)
    ) {
      return {
        message: 'errors.validation.greaterThan',
        values: { greaterThan: schema.greater_than },
      }
    }

      if (typeof schema.less_or_equal !== 'undefined' && typeof schema.less_than !== 'undefined') {
          const lessOrEqual = Number(schema.less_or_equal);
          const lessThan = Number(schema.less_than);
          const priorityValidator = lessThan <= lessOrEqual
              ? IntegerSchemaValues.LESS_THAN
              : IntegerSchemaValues.LESS_OR_EQUAL;

          if (priorityValidator === IntegerSchemaValues.LESS_OR_EQUAL
                && BigInt(Number(state)) > BigInt(schema.less_or_equal)) {
              return {
                  message: 'errors.validation.lessOrEqual',
                  values: { lessOrEqual: schema.less_or_equal },
              }
          }
          if (priorityValidator === IntegerSchemaValues.LESS_THAN
                && BigInt(Number(state)) >= BigInt(schema.less_than)) {
              return {
                  message: 'errors.validation.lessThan',
                  values: { lessThan: schema.less_than },
              }
          }
      }

      if (
      typeof schema.less_or_equal !== 'undefined' &&
      BigInt(Number(state)) > BigInt(schema.less_or_equal)
    ) {
      return {
        message: 'errors.validation.lessOrEqual',
        values: { lessOrEqual: schema.less_or_equal },
      }
    }
    if (
      typeof schema.less_than !== 'undefined' &&
      BigInt(Number(state)) >= BigInt(schema.less_than)
    ) {
      return {
        message: 'errors.validation.lessThan',
        values: { lessThan: schema.less_than },
      }
    }
  }

  if (!Number.isInteger(state)) {
    return { message: 'Must be an integer'}
  }

  if (state) {
    return { message: 'Invalid value' }
  }
}
