import {
    faArrowDown,
    faArrowUp,
    faClose,
    faEdit,
    faMinus,
    faMinusSquare,
    faPlus,
    faSave,
    faSlidersH,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ButtonType, IAppButton } from './types'
import { AppButtonVariant } from '../../../entity/AppButton'
import { withTooltip } from 't4b-core-frontend'

const variants = new Map<ButtonType, AppButtonVariant>()

const AppButton: React.FC<IAppButton> = ({
  onClick,
  className,
  variant,
  tooltip,
  tooltipPosition,
  disabled,
}) => {
  variants.set(
    'add',
    new AppButtonVariant(faPlus, tooltip ?? 'add', 'btn-plus')
  )
  variants.set(
    'delete',
    new AppButtonVariant(faTrashAlt, tooltip ?? 'delete', 'btn-delete')
  )
  variants.set(
    'edit',
    new AppButtonVariant(faEdit, tooltip ?? 'edit', 'btn-edit')
  )
  variants.set('up', new AppButtonVariant(faArrowUp, tooltip ?? 'up', 'btn-up'))
  variants.set(
    'down',
    new AppButtonVariant(faArrowDown, tooltip ?? 'down', 'btn-down')
  )
  variants.set(
    'cancel',
    new AppButtonVariant(faMinus, tooltip ?? 'cancel', 'btn-cancel')
  )
  variants.set(
    'save',
    new AppButtonVariant(faSave, tooltip ?? 'save changes', 'btn-save')
  )
  variants.set(
    'cancelChanges',
    new AppButtonVariant(
      faMinusSquare,
      tooltip ?? 'delete changes',
      'btn-cancelChanges'
    )
  )
  variants.set(
    'advancedSettings',
    new AppButtonVariant(
        faSlidersH,
      tooltip ?? 'Advanced settings',
      'btn-advancedSettings'
    )
  )
  variants.set('close', new AppButtonVariant(faClose, tooltip ?? '', ''))

  if (variants.has(variant)) {
    const selectedVariant =
      variants.get(variant) || new AppButtonVariant(faPlus, 'add', 'btn-plus')

    if (!disabled) {
      return withTooltip(
        <button
          disabled={disabled || false}
          onClick={onClick}
          className={classNames(
            'd-flex justify-content-center align-items-center btn btn-all',
            selectedVariant.className,
            className
          )}
        >
          <FontAwesomeIcon icon={selectedVariant.icon} />
        </button>,
        <FormattedMessage id={selectedVariant.text} />,
        selectedVariant.text,
        tooltipPosition || 'top'
      )
    }
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={classNames(
          'd-flex justify-content-center align-items-center btn btn-all',
          selectedVariant.className,
          className
        )}
      >
        <FontAwesomeIcon icon={selectedVariant.icon} />
      </button>
    )
  }
  return null
}

export default React.memo(AppButton)
