import { dump as yamlDump, load as yamlLoad } from 'js-yaml'
import { updateElementIds } from './updateElementIds'
import React from 'react'

export const readUpdateLoadYaml = (
    configFile: File,
    setUploadedConfig: React.Dispatch<React.SetStateAction<'' | File>>
) => {
    const reader = new FileReader()

    reader.onload = (e) => {
        try {
            const yamlContent = (e.target?.result as string) ?? '' // since reader.readAsText is used
            const parsedYaml = yamlLoad(yamlContent)
            updateElementIds(parsedYaml)
            const updatedYaml = yamlDump(parsedYaml)
            const updatedYamlFileObj = new File(
                [updatedYaml],
                configFile.name,
                {
                    type: configFile.type,
                }
            )
            setUploadedConfig(updatedYamlFileObj)
        } catch (error) {
            console.error(`Error parsing file ${configFile.name}`, error)
        }
    }
    reader.readAsText(configFile)
}
