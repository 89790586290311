import { TimeFormats } from '../../models/DateTime/types'
import moment from 'moment/moment'

interface IDatePickerValues {
    placeholderText?: string
    dateFormat?: string
    timeFormat?: string
    selected?: Date
    minDate?: Date
    maxDate?: Date
    minTime?: Date
    maxTime?: Date
    showTimeSelectOnly?: boolean
    unsupported?: boolean
}

export const getDatePickerValues = (
    timeFormat: TimeFormats,
    state: any,
    valueKey: string,
    minimumDateTime?: Date,
    maximumDateTime?: Date,
) => {
    const minDate = minimumDateTime ? new Date(minimumDateTime) : undefined
    const maxDate = maximumDateTime ? new Date(maximumDateTime) : undefined
    const minTime = minimumDateTime ? new Date(minimumDateTime) : undefined
    const maxTime = maximumDateTime ? new Date(maximumDateTime) : undefined

    const datePickerValues: Record<TimeFormats, IDatePickerValues> = {
        [TimeFormats.FULL_DATE_FULL_TIME]: {
            placeholderText: 'yyyy/MM/dd HH:mm:ss.SSS',
            dateFormat: 'yyyy/MM/dd HH:mm:ss.SSS',
            timeFormat: 'HH:mm:ss',
            selected: Date.parse(state?.[valueKey])
                ? new Date(state[valueKey])
                : undefined,
            minDate,
            maxDate,
        },
        [TimeFormats.DATE_HOURS_MINUTES_SECONDS]: {
            placeholderText: 'yyyy/MM/dd HH:mm:ss',
            dateFormat: 'yyyy/MM/dd HH:mm:ss',
            timeFormat: 'HH:mm:ss',
            selected: Date.parse(state?.[valueKey])
                ? new Date(state[valueKey])
                : undefined,
            minDate,
            maxDate,
        },
        [TimeFormats.DATE_HOURS_MINUTES]: {
            placeholderText: 'yyyy/MM/dd HH:mm',
            dateFormat: 'yyyy/MM/dd HH:mm',
            timeFormat: 'HH:mm',
            selected: Date.parse(state?.[valueKey])
                ? new Date(Date.parse(state?.[valueKey]))
                : undefined,
            minDate,
            maxDate,
        },
        [TimeFormats.DATE_AND_HOURS]: {
            placeholderText: 'yyyy/MM/dd HH',
            dateFormat: 'yyyy/MM/dd HH',
            timeFormat: 'HH:mm',
            selected: moment(state?.[valueKey], 'yyyy-MM-dd HH').isValid()
                ? new Date(
                      moment(
                          state[valueKey],
                          'yyyy-MM-dd HH'
                      ) as unknown as Date
                  )
                : undefined,
            minDate,
            maxDate,
        },
        [TimeFormats.FULL_TIME]: {
            placeholderText: 'HH:mm:ss.SSS',
            dateFormat: 'HH:mm:ss.SSS',
            timeFormat: 'HH:mm:ss.SSS',
            selected: moment(state?.[valueKey], 'HH:mm:ss.SSS').isValid()
                ? new Date(moment(state[valueKey], 'HH:mm:ss') as unknown as Date)
                : undefined,
            minTime,
            maxTime,
        },
        [TimeFormats.HOURS_MINUTES_SECONDS]: {
            showTimeSelectOnly: true,
            placeholderText: 'HH:mm:ss',
            dateFormat: 'HH:mm:ss',
            timeFormat: 'HH:mm:ss',
            selected: moment(state?.[valueKey], 'HH:mm:ss').isValid()
                ? new Date(
                      moment(state[valueKey], 'HH:mm:ss') as unknown as Date
                  )
                : undefined,
            minTime,
            maxTime,
        },
        [TimeFormats.HOURS_MINUTES]: {
            showTimeSelectOnly: true,
            placeholderText: 'HH:mm',
            dateFormat: 'HH:mm',
            timeFormat: 'HH:mm',
            selected: moment(state?.[valueKey], 'HH:mm').isValid()
                ? new Date(moment(state[valueKey], 'HH:mm') as unknown as Date)
                : undefined,
            minTime,
            maxTime,
        },
        [TimeFormats.HOURS]: {
            showTimeSelectOnly: true,
            placeholderText: 'HH',
            dateFormat: 'HH',
            timeFormat: 'HH',
            selected: moment(state?.[valueKey], 'HH').isValid()
                ? new Date(moment(state[valueKey], 'HH') as unknown as Date)
                : undefined,
            minTime,
            maxTime,
        },
        [TimeFormats.DATE]: {
            placeholderText: 'yyyy/MM/dd',
            dateFormat: 'yyyy/MM/dd',
            selected: Date.parse(state?.[valueKey])
                ? new Date(state[valueKey])
                : undefined,
            minDate,
            maxDate,
        },
        [TimeFormats.UNSUPPORTED_FORMAT]: {
            unsupported: true,
        },
    }

    return datePickerValues[timeFormat]
}
