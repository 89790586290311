import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { buildControlsExt, integerInput } from '../../inputs/controls'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IIntegerProps } from './types'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { initInputData } from '../../../utils/InitInputData'
import { numberInputErrors } from '../../../utils/validationErrors/numberInputErrors'

const Integer: FC<IIntegerProps> = ({
    data = {},
    dataSchema,
    name,
    wrapper,
    dataPath,
    pathWithElementID,
}) => {
    const dispatch = useDispatch()
    const [path] = useState<string[]>([...(dataPath || []), name])
    const [checkPath, setCheckPath] = useState<string[]>([])
    const { isChecked } = useTypedSelector((state) => state.configurations)
    const {
        is_required: isRequired,
        name: inputLabel,
        type,
        description: tooltipText,
        default: defaultValue,
    } = dataSchema
    const validationCheck = inputValidationCheck(type, dataSchema, data?.[name])
    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isValid,
    ] = useFormValidation(data || {}, { [name]: validationCheck })
    const [guid, , tabName] = path
    const label: string = inputLabel || getLabel(name)
    const className: string = !wrapper ? 'w-100' : ''
    const errorKey: string = pathWithElementID
        ? [...pathWithElementID, name].join('')
        : path.join('')
    const isDataEmpty: number = Object.keys(data || {}).length
    const isUndefined: boolean = typeof data?.[name] === 'undefined'
    const isEmpty: boolean = !data || !isDataEmpty || isUndefined

    useEffect(() => {
        if (isChecked[guid] && dataPath?.length) {
            isValid()
        }
    }, [isChecked]) // eslint-disable-line

    useEffect(() => {
        if (data && dataPath?.length) {
            setInputState(data)
        }
    }, [data]) // eslint-disable-line

    useEffect(() => {
        if (dataPath?.length) {
            setCheckPath(dataPath)
            initInputData(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    isRequired,
                    guid,
                    errorKey,
                    data,
                    setInputState,
                    tabName,
                },
                dispatch
            )
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (dataPath?.length) {
            inputDataDispatcher(
                {
                    isEmpty,
                    data,
                    name,
                    path,
                    dataSchema,
                    inputState,
                    validationCheck,
                    guid,
                    errorKey,
                    isUndefined,
                    checkPath,
                    type,
                },
                dispatch
            )
        }
    }, [inputState, touched, isChecked[guid]]) // eslint-disable-line

    const IntegerInput = buildControlsExt(
        [
            integerInput({
                name,
                tooltipText,
                label,
                validationType: type,
                className,
                isColumn: true,
                errMsg: numberInputErrors(inputState?.[name], dataSchema),
                isRequired,
                placeholder: defaultValue,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{IntegerInput}</div>
}

export default Integer
