import nullifyBlockData from '../../assets/icons/removeData.svg'
import enableBlockData from '../../assets/icons/tickData.svg'
import { ButtonWithTooltip } from './ButtonWithTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo'

interface BlockDataBntProps {
    state: boolean
    onClick: () => void
    className?: string
}

export const BlockDataBtn = (props: BlockDataBntProps) => {
    const { state, onClick, className } = props

    const tooltipMsg = !state ? (
        'Enable block'
    ) : (
        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <span>Disable block</span>
            <span>
                <FontAwesomeIcon icon={faCircleInfo} />&nbsp;
                <span style={{ color: '#A3A3A3', fontSize: '12px' }}>Block data will be deleted</span>
            </span>
        </span>
    )

    const btnIcon = (
        <img
            src={!state ? enableBlockData : nullifyBlockData }
            alt="Block data button"
        />
    )

    return (
        // @ts-ignore
        <ButtonWithTooltip tooltip={tooltipMsg}>
            <button
                style={{ width: '2rem' }}
                className={`btn-clean ${className}`}
                onClick={onClick}
            >
                {btnIcon}
            </button>
        </ButtonWithTooltip>
    )
}
