import { combineReducers } from 'redux'
import authReducer from './authReducer'
import { userReducer } from './userReducer'
import { modalReducer } from './modalReducer'
import { navBarReducer } from './navBarReducer'
import { rightBarReducer } from './rightbarReducer'
import { productsReducer } from './productsReducer'
import { configurationsReducer } from './configurationReducer'
import { infoReducer } from './infoReducer'
import licenceReducer from './licenceReducer'
import { logsReducer } from './logsReducer'
import { tableViewReducer } from './tableViewReducer'
import { sideBarReducer } from './sideBarReducer'
import { notificationsReducer } from './notificationsReducer'
import { actionsReducer } from './actionsReducer'

export const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    modal: modalReducer,
    navBar: navBarReducer,
    rightBar: rightBarReducer,
    products: productsReducer,
    configurations: configurationsReducer,
    licence: licenceReducer,
    info: infoReducer,
    logs: logsReducer,
    notifications: notificationsReducer,
    tableView: tableViewReducer,
    sideBar: sideBarReducer,
    actions: actionsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
