import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import SideBarItem from '../SideBarItem/SideBarItem'
import { routes } from '../../router'
import { User, UserPermissions } from '../../entity/User'
import { fetchProducts } from '../../store/actions/products/productsActions'
import { fetchQuickAccessProducts } from '../../store/actions/products/quickAccessActions'
import { fetchProductsGroups } from '../../store/actions/products/groupsActions'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import licenseInfo from '../../assets/icons/license_info1.svg'
import { Period } from '../modals/LogsModal/utils'
import { downloadServerLogs } from '../../store/actions/logsActions'
import { fetchNotifications } from '../../store/actions/notificationsActions'
import * as signalR from '@microsoft/signalr'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { NotificationsActions } from '../../types/notifications'
import { LogsActions } from '../../types/logs'
import { getJwt } from '../../utils/authentication'
import { IProduct } from '../../types/product'
import { getNotificationsFilter } from '../modals/NotificationsModal/utils/getNotificationsFilter'

interface ISideBar {
    sidebarHidden: boolean
}

const SideBar: FC<ISideBar> = ({ sidebarHidden }) => {
  const dispatch = useDispatch()
  const user = new User()

    const { products } = useTypedSelector((store) => store.products)

  const onLicenseClick = (): void => {
    dispatch(showModal(ModalTypes.LICENCE_MODAL, {}))
  }

  const licence: JSX.Element = (
    <div className="d-flex h-60px w-100 license">
      <div
        className={style.bottomItem}
        onClick={onLicenseClick}
      >
        <img src={licenseInfo} alt='license info' />
        {sidebarHidden && (
          <div className="text-light">Toolbox</div>
        )}
      </div>
    </div>
  )

    const handleGetServerLogs = (): void => {
        dispatch(showModal(ModalTypes.LOGS_MODAL, {
            dispatcher: (dates: Period) => dispatch(downloadServerLogs(dates))
        }))
    }

  const serverLogs: JSX.Element = (
    <div className="d-flex h-60px w-100">
      <div
        className={style.bottomItem}
        onClick={handleGetServerLogs}
      >
        <FontAwesomeIcon
          icon={faDownload}
          size="1x"
          className="text-white"
        />
        {sidebarHidden && (
          <div className="text-light">Logs</div>
        )}
      </div>
    </div>
  )

  useEffect(() => {
    dispatch(fetchProducts(true))
    dispatch(fetchQuickAccessProducts())
    dispatch(fetchProductsGroups())
    if (user.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION)) {
        const notificationsFilterProductsIds = getNotificationsFilter()
        dispatch(fetchNotifications(notificationsFilterProductsIds))
    }
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchProducts()), 30000)
    return () => clearInterval(interval)
  }, []) // eslint-disable-line

    // ======= SignalR
    useEffect(() => {
        if (
            user.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION)
            && !!products.length
        ) {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl('/signalr', {
                    accessTokenFactory: () => getJwt() ?? '',
                    withCredentials: true,
                    transport:
                        process.env.NODE_ENV === 'production'
                            ? undefined
                            : signalR.HttpTransportType.WebSockets,
                })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build()

            const receiveLiveLogs = () => {
                connection.on('log_batch', (logsBatch, productGuid) => {
                    const logs = logsBatch['log_batch']
                    dispatch({
                        type: LogsActions.NEW_LIVE_LOGS,
                        payload: { productGuid, data: logs }
                    })
                })
            }

            const receiveNotifications =  () => {
                connection.on('new_notifications', (notifications) => {
                    dispatch({
                        type: NotificationsActions.NEW_NOTIFICATIONS,
                        payload: notifications
                    })
                })
            }

            const subscribeToProductLogs = async () => {
                if (!!products.length) {
                    try {
                        const subscriptions = products.map((product: IProduct) => {
                            return connection.send('log_subscription', product.guid)
                        })
                        return await Promise.allSettled(subscriptions)
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            const start = async() => {
                try {
                    await connection.start();
                } catch (err) {
                    console.error(err)
                }
            }

            const startSignalRCommunication = async () => {
                try {
                    receiveLiveLogs()
                    receiveNotifications()
                    await start()
                } catch (e) {
                    console.error(e)
                }
            }

            connection.onclose(async () => {
                await start();
                await subscribeToProductLogs();
            });

            // Start the connection.
            startSignalRCommunication().then(() => {
                console.log('SignalR connection started')
                return subscribeToProductLogs()
            })
                .then(console.log)
                .catch(console.error)

            return () => {
                connection.stop()
            }
        }
    }, [products.length, dispatch]) // no deps here !important

  return (
      <div
          className={`bg-dark ${style.wrapper} ${
              sidebarHidden ? style.show : style.hidden
          }`}
      >
          <div className="w-100">
              <div className={style.barContainer}>
                  {routes.map((item: any) => {
                      if (user.permissions.includes(item.permission)) {
                          return (
                              <SideBarItem
                                  name={item.name}
                                  route={item.path}
                                  icon={item.icon}
                                  dropdown={item.dropdown}
                                  sidebarHidden={sidebarHidden}
                                  key={item.name}
                              />
                          )
                      }
                      return <div key={item.name}></div>
                  })}
              </div>
          </div>
          {user.permissions.includes(UserPermissions.USER_ADMINISTRATION) && serverLogs}
          {licence}
      </div>
  )
}

export default SideBar
