import React, { useEffect } from 'react'
import { fetchProductActionsHistory } from '../../../store/actions/actionsActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ActionHistoryRow } from '../ActionHistoryRow/ActionHistoryRow'
import styles from './ActionsHistry.module.scss'

interface ActionsHistoryProps {
    className?: string
    guid: string
}

export const ActionsHistory = (props: ActionsHistoryProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    const actionsHistoryItems = useTypedSelector(
        (store) => store.actions.actionsHistory[guid]
    )

    useEffect(() => {
        dispatch(fetchProductActionsHistory(guid))
    }, [dispatch, guid])

    return (
            <div>
                <h5>Actions history</h5>
                {!!actionsHistoryItems?.length ? (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.tableHead}>TIME</th>
                                <th className={styles.tableHead}>ACTION</th>
                                <th className={styles.tableHead}>STATUS</th>
                                <th className={styles.tableHead}>RESULT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {actionsHistoryItems.map((actionHistory) => (
                                <ActionHistoryRow
                                    key={actionHistory.action_id}
                                    actionHistoryItem={actionHistory}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={`${styles.noData} m-auto`}>No data</div>
                )}
            </div>
    )
}
