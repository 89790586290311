export enum TableViewActionType {
    TABLE_VIEW= 'TABLE_VIEW',
}

const initialState = localStorage.getItem('tableView') === 'true' || false

export const tableViewReducer = (
    state = initialState,
    action: { type: TableViewActionType, payload: boolean }
) => {

    switch (action.type) {
        case TableViewActionType.TABLE_VIEW: {
            localStorage.setItem('tableView', String(action.payload));
            return action.payload
        }

        default: {
            return state
        }
    }
}
