import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Modal } from 'react-bootstrap'
import { hideModal } from '../../../store/actions/modalActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import style from './NotificationsModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import {
    fetchNotifications,
    fetchNotificationsOnScroll,
    markNotificationsRead,
} from '../../../store/actions/notificationsActions'
import { Notification } from './Notificaation/Notificaation'
import { FormattedMessage } from 'react-intl'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { NotificationsFilter } from './NotificationsFilter/NotificationsFilter'
import { getNotificationsVisible } from './notificationsUtils/getNotificationsVisisble'
import { useSessionStorage } from '../../../hooks/useSessionStroage'
import { getNotificationsFilter } from './utils/getNotificationsFilter'

export const NotificationsModal = () => {
    const dispatch = useAppDispatch()
    const [filterOpen, setFilterOpen] = useState(false)
    const [selected, setSelected] = useState<string[]>([])
    const [checkedProducts, setCheckedProducts] = useSessionStorage<string[]>(
        'notificationsFilter',
        []
    )
    const { notifications } = useTypedSelector((store) => store.notifications)
    const [notificationsVisible, setNotificationsVisible] = useState(
        getNotificationsVisible(checkedProducts, notifications)
    )

    const unreadNotificationIds = useMemo(
        () =>
            notificationsVisible.reduce((acc: string[], notis) => {
                if (notis.status === 'unread') {
                    acc = [...acc, notis.notification_id]
                }
                return acc
            }, []),
        [notificationsVisible]
    )

    const handleFilterClose = () => setFilterOpen(false)
    const handleModalClose = () => dispatch(hideModal())

    const handleReadSelected = () => {
        dispatch(markNotificationsRead(selected))
        setTimeout(() => setSelected([]))
    }

    const handleReadAll = () => {
        dispatch(markNotificationsRead(unreadNotificationIds))
    }

    const handleNotificationCheck = (
        isChecked: boolean,
        notificationId: string
    ) => {
        if (isChecked) {
            setSelected((prev) => [...prev, notificationId])
        } else {
            setSelected((prev) => prev.filter((id) => id !== notificationId))
        }
    }

    const handleLoadMore = () => {
        const lastNotificationId = notificationsVisible.at(-1)?.notification_id
        const notificationsFilterProductsIds = getNotificationsFilter()
        dispatch(
            fetchNotificationsOnScroll(
                lastNotificationId,
                notificationsFilterProductsIds
            )
        )
    }

    useEffect(() => {
        const notificationsFilterProductsIds = getNotificationsFilter()
        dispatch(fetchNotifications(notificationsFilterProductsIds))
    }, [checkedProducts.length, dispatch])

    useEffect(() => {
        setNotificationsVisible(
            getNotificationsVisible(checkedProducts, notifications)
        )
    }, [notifications, checkedProducts.length])

    return (
        <Modal
            show
            scrollable
            onHide={handleModalClose}
            dialogClassName={style.customModal}
            backdropClassName={style.customBackdrop}
        >
            <Modal.Header
                onClick={handleFilterClose}
                className={style.header}
            >
                <Modal.Title>
                    Notifications &nbsp;
                    <span className={style.notisCount}>
                        {unreadNotificationIds.length}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <div className={style.controls}>
                {!!unreadNotificationIds.length && (
                    <>
                        <button
                            className={`btn-clean ${style.controlBtn}`}
                            onClick={handleReadAll}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Mark all as read</span>
                        </button>
                        <button
                            className={`btn-clean ${style.controlBtn}`}
                            onClick={handleReadSelected}
                            disabled={!selected.length}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Mark selected as read</span>
                        </button>
                    </>
                )}
                <NotificationsFilter
                    filterOpen={filterOpen}
                    setFilterOpen={setFilterOpen}
                    checkedProducts={checkedProducts}
                    setCheckedProducts={setCheckedProducts}
                />
            </div>
            <Modal.Body
                onClick={handleFilterClose}
                className={style.customBody}
            >
                <div className={style.notisContainer}>
                    {!!notificationsVisible.length ? (
                        notificationsVisible.map((notis) => (
                            <Notification
                                key={notis.notification_id}
                                notification={notis}
                                onNotificationCheck={handleNotificationCheck}
                                selected={selected.includes(
                                    notis.notification_id
                                )}
                            />
                        ))
                    ) : (
                        <div className={style.noData}>No data</div>
                    )}
                    {!!notifications.length && (
                        <button
                            onClick={handleLoadMore}
                            className={`${style.more} m-auto`}
                        >
                            <FormattedMessage id="buttons.moreNotifications" />
                            <span className="ms-2">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                        </button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}
