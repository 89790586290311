import { inputValidationCheck } from '../../../../validators/inputValidators/inputValidators'

export const getValidationSchema = (schema: any) => {
  let result = {}
  Object.keys(schema).map(
    (el: any) =>
      (result = {
        ...result,
        [el]: inputValidationCheck(schema[el]?.type, schema[el]),
      })
  )
  return result
}
