import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IProduct } from '../../../types/product'
import { IDropdownItem } from '../types'

export const DropdownItem: FC<IDropdownItem> = ({
    entry,
    inputState,
    setInputState,
    isGroupChecked,
    otherProductsItem,
}) => {
    const { products } = useTypedSelector((state) => state.products)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState(false)
    const [hovered, setHovered] = useState(false)
    const product = products.find((product: IProduct) => product.guid === entry)
    const isAllProductsChecked = inputState.visibility?.all_products
    const productsList = inputState.visibility.products
    const isChecked =
        inputState.visibility?.products?.indexOf(product?.guid) > -1

    const onCheckHandle = (event: any): void => {
        setState(event.target.checked)
        if (event.target.checked) {
            setInputState((prev: typeof inputState) => {
                const products = prev.visibility?.products
                    ? [...prev.visibility.products, product.guid]
                    : [product.guid]
                const groups = prev.visibility?.groups
                    ? [...prev.visibility.groups]
                    : []
                const visibility = { all_products: false, groups, products }
                return {
                    ...prev,
                    visibility,
                }
            })
        } else {
            setInputState((prev: typeof inputState) => ({
                ...prev,
                visibility: {
                    ...prev.visibility,
                    products: prev.visibility.products.filter(
                        (el: string) => el !== product.guid
                    ),
                },
            }))
        }
    }

    const handleOnClick = () => setOpen(!open)

    const handleOnMouseEnter = (): void => {
        setHovered(true)
    }

    const handleOnMouseLeave = (): void => {
        setHovered(false)
    }

    useEffect(() => {
        // the following if spaghetti has been refactored to make the feature work
        if (isChecked || isAllProductsChecked || isGroupChecked) {
            setState(true)
            return
        }
        if (!isAllProductsChecked || !isGroupChecked) {
            setState(false)
        }
        if (!isAllProductsChecked && otherProductsItem) {
            setState(false)
        }
        if (!productsList.includes(product.guid)) {
            setState(false)
        }
    }, [isAllProductsChecked, isGroupChecked, productsList])

    return (
        <div className={`product-dropdown-item ${open && 'pb-1'}`}>
            <div
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                className="d-flex justify-content-between w-100"
            >
                <label>
                    <input
                        type="checkbox"
                        className="checkbox-lg pointer me-1"
                        onChange={onCheckHandle}
                        checked={state}
                        name={product?.guid}
                        disabled={isAllProductsChecked || isGroupChecked}
                    />
                    {product?.display_name || product?.name}
                </label>
                {hovered ? (
                    <div>
                        <FontAwesomeIcon
                            onClick={handleOnClick}
                            className="icon"
                            icon={faFolder}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <Collapse in={open}>
                <div className="rounded border p-2 ">{product?.path}</div>
            </Collapse>
        </div>
    )
}
