export enum ModalTypes {
  ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT',
  ADD_NEW_USER_MODAL = 'ADD_NEW_USER_MODAL',
  MAIN_MODAL = 'MAIN_MODAL',
  LICENCE_MODAL = 'LICENCE_MODAL',
  PRODUCT_UNAVAILABLE_MODAL = 'PRODUCT_UNAVAILABLE_MODAL',
  ERROR_MODAL = 'ERROR_MODAL',
  INFORM_MODAL = 'INFORM_MODAL',
  CREATE_PRODUCTS_GROUPS_MODAL = 'CREATE_PRODUCTS_GROUPS_MODAL',
  DELETE_PRODUCTS_GROUP_MODAL = 'DELETE_PRODUCTS_GROUP_MODAL',
  EDIT_GROUPS_MODAL = 'EDIT_GROUPS_MODAL',
  LOGS_MODAL = 'LOGS_MODAL',
  CSV_MODAL = 'CSV_MODAL',
  NOTIFICATIONS_MODAL = 'NOTIFICATIONS_MODAL',
  CONFIGS_MODAL = 'CONFIGS_MODAL',
  UPLOAD_CONFIGS_MODAL = 'UPLOAD_CONFIGS_MODAL',
}

export enum ModalActionType {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}
