import React, { useEffect, useState } from 'react'
import { Badge, Dropdown } from 'react-bootstrap'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import styles from './NotificationsFilter.module.scss'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { NotificationsFilterItem } from './NotificationsFilterItem'
import { IProduct } from '../../../../types/product'

interface NotificationsFilterProps {
    filterOpen: boolean
    setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
    checkedProducts: string[]
    setCheckedProducts: (
        value: ((prevState: string[]) => string[]) | string[]
    ) => void
}

export const NotificationsFilter = (props: NotificationsFilterProps) => {
    const { filterOpen, setFilterOpen, checkedProducts, setCheckedProducts } =
        props

    const [allChecked, setAllChecked] = useState(false)
    const { products } = useTypedSelector((store) => store.products)

    const handleToggle = () => setFilterOpen((prev) => !prev)

    const handleOnChange = (productId: string) => {
        const productIsChecked = checkedProducts.includes(productId)
        if (productIsChecked) {
            const productsFiltered = checkedProducts.filter(
                (prodId) => prodId !== productId
            )
            setCheckedProducts(productsFiltered)
        } else {
            setCheckedProducts((prev) => [...prev, productId])
        }
    }

    const handleCheckAll = () => {
        setAllChecked((prevAllChecked) => {
            if (prevAllChecked) {
                setCheckedProducts([])
                return false
            } else {
                const allProductsIds = products.reduce(
                    (acc: string[], cur: IProduct) => [...acc, cur.guid],
                    []
                )
                setCheckedProducts(allProductsIds)
                return true
            }
        })
    }

    const isAllChecked = products.length === checkedProducts.length

    useEffect(() => {
        setAllChecked(isAllChecked)
    }, [isAllChecked])

    useEffect(() => {
        return () => setFilterOpen(false)
    }, [])

    return (
        <Dropdown show={filterOpen} className="ml-auto">
            <DropdownToggle
                onClick={handleToggle}
                style={{ boxShadow: 'unset', fontSize: 'unset' }}
                className={`bg-transparent border-0 px-2 py-1 ${styles.filterToggle}`}
            >
                <FontAwesomeIcon icon={faFilter} />
                &nbsp;Filter
                {!!checkedProducts.length && (
                    <Badge
                        pill
                        className={styles.badge}
                    >
                        <span>{checkedProducts.length}</span>
                    </Badge>
                )}
            </DropdownToggle>
            {!!products.length ? (
                    <DropdownMenu>
                        <Dropdown.Item
                            onClick={handleCheckAll}
                            className="d-flex gap-2"
                        >
                            <input
                                id="all-products"
                                type="checkbox"
                                checked={allChecked}
                            />
                            <label htmlFor="all-products">All products</label>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <div className={styles.listItems}>
                            {products.map((product: IProduct) => (
                                <NotificationsFilterItem
                                    key={product.guid}
                                    product={product}
                                    onChange={handleOnChange}
                                    checkedProducts={checkedProducts}
                                />
                            ))}
                        </div>
                    </DropdownMenu>
            ) : (
                <div className="m-auto py-2">No products</div>
            )}
        </Dropdown>
    )
}
