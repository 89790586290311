import { Dropdown } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { IProduct } from '../../../../types/product'

interface NotificationsFilterItemProps {
    product: IProduct
    onChange: (productId: string) => void
    checkedProducts: string[]
}
export const NotificationsFilterItem = (
    props: NotificationsFilterItemProps
) => {
    const { product, onChange, checkedProducts } = props

    const [ isChecked, setIsChecked ] = useState(checkedProducts.includes(product.guid))

    useEffect(() => {
        const isCheckedNew = checkedProducts.includes(product.guid)
        const hasChanged = isChecked !== isCheckedNew

        if (hasChanged) setIsChecked(isCheckedNew)
    }, [checkedProducts.length])

    const handleChange = () => onChange(product.guid)

    return (
        <Dropdown.Item onClick={handleChange} className="d-flex gap-2">
            <input
                type="checkbox"
                checked={isChecked}
            />
            <span>{product.name}</span>
        </Dropdown.Item>
    )
}
