export const getDateTimeSchema = (date: any, schema: any) => {
    if (schema.is_required) {
        date = date.required()
    }

    if (!schema.is_required) {
        date = date
            .notRequired()
            .nullable()
    }

    return date
}
