import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { IProduct } from '../../../../types/product'
import { getOtherProducts } from '../../../../utils/filters'
import {
    buildControlsExt,
    multiSelectInput,
    stringInput,
} from '../../../inputs/controls'
import { IGroupData } from '../types'

interface IGroup {
    groupState: IGroupData
    setGroupState: any
    touched: any
    setTouched: any
    validationResult: { [key: string]: boolean }
}

export const Group: FC<IGroup> = ({
  groupState,
  setGroupState,
  touched,
  setTouched,
  validationResult,
}) => {
  const { products, productsGroups } = useTypedSelector(
    (state) => state.products
  )
  const productsList = getOtherProducts(productsGroups, products)
  const entriesOptions = productsList.map((product: IProduct) => ({
    label: product.display_name || product.name,
    value: product.guid,
  }))

  const form = buildControlsExt(
    [
      stringInput({
        name: 'name',
        label: 'Name',
        isColumn: true,
        errMsg: { message: 'errors.validation.fieldRequired' },
      }),
      multiSelectInput({
        name: 'entries',
        isColumn: true,
        options: entriesOptions,
        label: 'Products',
      }),
    ],
    groupState,
    setGroupState,
    '',
    touched,
    setTouched,
    validationResult
  )

  return (
    <div>
      <Form>{form}</Form>
    </div>
  )
}
