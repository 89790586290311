import { INotification } from '../../../../types/notifications'

export const getNotificationsVisible = (
    checkedProducts: string[],
    notifications: INotification[]
) =>
    !!checkedProducts.length
        ? notifications.filter((notis) =>
              checkedProducts.includes(notis.product_id)
          )
        : notifications
