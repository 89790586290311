import axios from 'axios'
import { Dispatch } from 'redux'
import { ProductActionType } from '../../../types/product'
import { alertErrorMessage, throwSuccessMessage } from '../../../utils/Errors'
import { apiVersion } from '../../../versions'
import { getHeaders } from '../http'
import { ProductStatus } from '../../../entity/Product'
import fileDownload from 'js-file-download'
import { fetchProduct } from '../configurationActions'
import { getContentDispositionFilename } from '../../../utils/getContentDispositionFilename'

const API = `/api/${apiVersion}`
const API_PRODUCTS = `${API}/products`

export const fetchProducts = (isFirst?: boolean): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      if (isFirst) {
        dispatch({ type: ProductActionType.FETCH_PRODUCTS })
      }
      const { data } = await axios.get(API_PRODUCTS, {
        headers: getHeaders(),
      })
      dispatch({
        type: ProductActionType.FETCH_PRODUCTS_SUCCESS,
        payload: data,
      })
    }
    catch (e: any) {
      dispatch({
        type: ProductActionType.FETCH_PRODUCTS_ERROR,
        payload: 'Fetching error',
      })
      alertErrorMessage(e);
    }
  }
}

export const deleteProduct = (
  data: { guid: string; name: string },
  cb?: Function
): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API_PRODUCTS}/${data.guid}`, {
        headers: getHeaders(),
      })
      throwSuccessMessage(`Success! ${data.name} deleted!`)
      dispatch({
        type: ProductActionType.DELETE_PRODUCT_SUCCESS,
        payload: { guid: data.guid },
      })
      if (cb) {
        cb()
      }
    }
    catch (e: any) {
      alertErrorMessage(e);
    }
  }
}

export const deleteProducts = (products: string[]): any => {
  const requests = products.map((guid: string) =>
    axios.delete(`${API_PRODUCTS}/${guid}`, {
      headers: getHeaders(),
    })
  )

  if (products.length) {
    return async (dispatch: Dispatch<any>) => {
      try {
        await axios.all(requests)
        throwSuccessMessage(`Success!`)
        dispatch(fetchProducts())
      }
      catch (e: any) {
        alertErrorMessage(e);
      }
    }
  }
}

export const renameProduct = (guid: string, name: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ProductActionType.RENAME_PRODUCT, payload: { guid } })
      await axios.put(`${API_PRODUCTS}/${guid}/name`, {
        headers: getHeaders(),
        name,
      })
      dispatch(fetchProducts())
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_SUCCESS,
        payload: { guid },
      })
      localStorage.setItem(`${guid}-productName`, name)
      throwSuccessMessage('Success!')
    }
    catch (e: any) {
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_ERROR,
        payload: { guid },
      })
      alertErrorMessage(e);
    }
  }
}

export const changeProductStatus = (guid: string, newStatus: ProductStatus): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(`${API_PRODUCTS}/${guid}/status`, {
        status: newStatus
      })
      dispatch(fetchProducts())
      throwSuccessMessage('Success!')
    }
    catch (e) {
      alertErrorMessage(e);
    }
  }
}

export const downloadProductConfig = (guid: string, configName: string) => {
  return async () => {
    try {
      const response = await axios.get(`${API_PRODUCTS}/${guid}/configuration_file`, {
        headers: getHeaders(),
        responseType: 'blob',
        params: {
          config_key: configName
        }
      })

      if (response.status === 200) {
        const filename = getContentDispositionFilename(response)
        fileDownload(response.data, filename)
      }
    }
    catch (e) {
      alertErrorMessage(e);
    }
  }
}

export const uploadProductConfig = (guid: string, formData: FormData, filename: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await axios.post(
          `${API_PRODUCTS}/${guid}/configuration_file`,
          formData,
          { headers: getHeaders({
              'Content-Type': 'multipart/formdata',
              'Content-Disposition': `attachment; filename=${filename}`
          })}
      )

      if (response.status === 200) {
        throwSuccessMessage('Config uploaded')
        dispatch(fetchProduct(guid, true))
      }
    }
    catch (e) {
      alertErrorMessage(e);
    }
  }
}
