export enum SideBarActionType {
    TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
}

export const sideBarReducer = (
    state = false,
    action: { type: string, payload: boolean}
) => {
    if (action.type === SideBarActionType.TOGGLE_SIDEBAR) {
        return action.payload
    }
    return state
}
