import { Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideModal } from '../../../store/actions/modalActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice'
import { ChangeEvent, useState } from 'react'
import { downloadProductConfig } from '../../../store/actions/products/productsActions'

export const ConfigsModal = () => {
    const dispatch = useAppDispatch()

    const {
        data: { guid },
    } = useTypedSelector((store) => store.modal)

    const { productsConfigurations } = useTypedSelector(
        (store) => store.configurations
    )
    const productSchemaAndConfig = productsConfigurations[guid]
    const configuration = productSchemaAndConfig.configuration
    const configNames = Object.keys(configuration)

    const [pickedConfig, setPickedConfig] = useState('')

    const handleClose = () => dispatch(hideModal())

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        setPickedConfig(e.target.value)

    const handleDownload = () => {
        dispatch(downloadProductConfig(guid, pickedConfig))
        setTimeout(() => handleClose(), 0)
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="px-4 py-3"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage id="modals.configs.downloadTitle" />
                    </Modal.Title>
                </Modal.Header>
                <div
                    id="body"
                    className="px-4 py-3"
                >
                    <Form>
                        {configNames.map((configName) => (
                            <Form.Check
                                key={configName}
                                value={configName}
                                id={configName}
                                onChange={handleChange}
                                type="radio"
                                className="mb-2"
                                name="configsGroup"
                                label={
                                    <>
                                        <span className="mx-1">
                                            {configName}
                                        </span>
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                    </>
                                }
                            />
                        ))}
                    </Form>
                </div>
                <Modal.Footer>
                    <button
                        disabled={!pickedConfig}
                        className="app-btn-main app-btn-apply"
                        onClick={handleDownload}
                    >
                        Download
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
