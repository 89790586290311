export const validationActions = {
    cleanInvalidField: (guid: string, tabName: string, errorKeyName: string, invalidFields: any) => {
        delete invalidFields?.[guid]?.[tabName]?.[errorKeyName]
    },

    cleanBlockInvalidFields: (
        guid: string,
        tabName: string,
        errorKeyName: string,
        invalidFields: any
    ) => {
        const tabInvalidFieldsObj = invalidFields[guid]?.[tabName] ?? {}
        const tabInvalidFieldKeys = Object.keys(tabInvalidFieldsObj)
        const errorKeyLikeKeys = tabInvalidFieldKeys.filter(
            (invalidKeyName: string) => invalidKeyName.startsWith(errorKeyName)
        )
        for (let key of errorKeyLikeKeys) {
            delete tabInvalidFieldsObj[key]
        }
    },
}
