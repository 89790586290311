import { apiVersion } from '../../versions'
import { Dispatch } from 'redux'
import { alertErrorMessage } from '../../utils/Errors'
import axios from 'axios'
import { getHeaders } from './http'
import { NotificationsActions } from '../../types/notifications'

const API = `/api/${apiVersion}/notifications`

// notifications are fetched in sidebar
export const fetchNotifications =
    (productIds?: string[]): any => async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(API, {
                headers: getHeaders(),
                params: {
                    product_ids: productIds
                },
                paramsSerializer: {
                    indexes: null,
                },
            })

            dispatch({
                type: NotificationsActions.FETCH_NOTIFICATIONS_SUCCESS,
                payload: data,
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }

export const fetchNotificationsOnScroll =
    (lastNotificationId: string | undefined = '', productIds: string[]): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(API, {
                headers: getHeaders(),
                params: {
                    before: lastNotificationId,
                    product_ids: productIds
                },
                paramsSerializer: {
                    indexes: null,
                },
            })

            dispatch({
                type: NotificationsActions.FETCH_NOTIFICATIONS_ONSCROLL,
                payload: data ?? [],
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }

export const markNotificationsRead =
    (notificationsIds: string[]): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(`${API}/read`, notificationsIds, {
                headers: getHeaders(),
            })
            dispatch({
                type: NotificationsActions.MARK_NOTIFICATIONS_READ,
                payload: notificationsIds,
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }
