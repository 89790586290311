import { Dispatch } from 'redux'
import { ConfigurationsActionType } from '../../../types/configurations'
import { checkUnsavedChanges } from '../configurationActions'

export const initEmptyData = (
    name: string,
    path: string[],
    schema: any,
    type?: any
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ConfigurationsActionType.INIT_EMPTY_DATA,
            payload: { name, schema, path, type },
        })
        dispatch(checkUnsavedChanges(path[0]))
    }
}

export const dateTimeActions = {
  modify(componentState: any, path: string[], data: any, name: string) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.DATE_TIME_MODIFY,
        payload: { componentState, path, data, name },
      })
    }
  },
}

export const inputActions = {
  modify(
    componentState: any,
    path: string[],
    data: any,
    name: string,
    type?: string,
    isEmptyAsNull?: boolean
  ) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.INPUT_MODIFY,
        payload: { componentState, path, data, name, type, isEmptyAsNull },
      })
      dispatch(checkUnsavedChanges(path[0]))
    }
  },
}

export const enumActions = {
  modify(componentState: any, path: string[], data: any) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.ENUM_MODIFY,
        payload: { componentState, path, data },
      })
      dispatch(checkUnsavedChanges(path[0]))
    }
  },
}

export const flagActions = {
  modify(componentState: any, path: string[], data: any) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.FLAG_MODIFY,
        payload: { componentState, path, data },
      })
      dispatch(checkUnsavedChanges(path[0]))
    }
  },
}

export const blockDictionaryActions = {
  addItem(path: string[], data: any, item: any) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.BLOCK_DICTIONARY_ADD,
        payload: { path, data, item },
      })
    }
  },
  deleteItem(path: string[], data: any, item: any) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: ConfigurationsActionType.BLOCK_DICTIONARY_DELETE,
        payload: { path, data, item },
      })
      dispatch(checkUnsavedChanges(path[0]))
    }
  },
}
