import React, { FC, useEffect } from 'react'
import { Button, Form, Modal, Nav } from 'react-bootstrap'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchInfo } from '../../store/actions/infoActions'
import { hideModal } from '../../store/actions/modalActions'
import { actualSchemaVersion, uiVersion } from '../../versions'

const LicenceModal: FC = () => {
    const dispatch = useAppDispatch()
    const { info } = useTypedSelector((state: any) => state.info)
    const {
        app_version: appVersion,
        supported_api_version: supportedApiVersions,
        supported_socket_versions: supportedSocketVersions,
        socket_address: socketAddress = '',
    } = info
    const formattedSocketAddress = socketAddress.replace(
        '{DOMAIN}',
        window.location.hostname
    )
    const supportedSocketVersionsJoined = supportedSocketVersions?.join(' ')

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleOnSubmit = (): void => {
        handleClose()
    }

    useEffect(() => {
        dispatch(fetchInfo())
    }, [dispatch])

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header className="px-4 py-3">
                    <Modal.Title>Toolbox</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4 py-3">
                    <div>
                        <div className="d-flex">
                            <div>
                                <span>
                                    <span className="fw-450">UI version:</span>{' '}
                                    {uiVersion}
                                </span>
                                <br />
                                {appVersion && (
                                    <>
                                        <span>
                                            <span className="fw-450">
                                                App version:
                                            </span>{' '}
                                            {appVersion || '-'}
                                        </span>
                                        <br />
                                    </>
                                )}
                                <span>
                                    <span className="fw-450">
                                        Schema version:
                                    </span>{' '}
                                    {actualSchemaVersion}
                                </span>
                                <br />
                            </div>
                            <div className="ms-4">
                                {supportedApiVersions && (
                                    <>
                                        <span>
                                            <span className="fw-450">
                                                Supported API versions:
                                            </span>{' '}
                                            {supportedApiVersions || '-'}
                                        </span>
                                        <br />
                                    </>
                                )}
                                {supportedSocketVersionsJoined && (
                                    <>
                                        <span>
                                            <span className="fw-450">
                                                Supported socket versions:
                                            </span>{' '}
                                            {supportedSocketVersionsJoined ||
                                                '-'}
                                        </span>
                                        <br />
                                    </>
                                )}
                            </div>
                        </div>
                        {formattedSocketAddress && (
                            <div className="mt-1">
                                <span>
                                    <span className="fw-450">
                                        Websocket address:
                                    </span>{' '}
                                    <span className="font-italic">
                                        {formattedSocketAddress || '-'}
                                    </span>
                                </span>
                                <br />
                            </div>
                        )}
                        <div>
                            <Nav.Link
                                href={`${window.location.protocol}//${window.location.host}/swagger/index.html`}
                                className="fw-450 ps-0 mb-0 pb-0"
                                target="_blank"
                            >
                                API description
                            </Nav.Link>
                        </div>
                    </div>
                </Modal.Body>
                <Form>
                    <Modal.Footer className="px-4 py-3">
                        <div className="d-flex w-100 flex-1 justify-content-between align-items-center mx-0">
                            <span>© 2023 - Tools For Brokers PTE LTD</span>
                            <Button
                                variant="primary"
                                className="app-btn-main app-btn-apply"
                                type="button"
                                onClick={handleOnSubmit}
                            >
                                Ok
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default LicenceModal
