import {
    INotification,
    INotificationReducer,
    NotificationsActions,
} from '../../types/notifications'

const initialState: INotificationReducer = {
    notifications: [],
    isLoading: false,
}

export const notificationsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): INotificationReducer => {
    switch (action.type) {
        case NotificationsActions.FETCH_NOTIFICATIONS_SUCCESS: {
            return { ...state, notifications: action.payload }
        }

        case NotificationsActions.FETCH_NOTIFICATIONS_ONSCROLL: {
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload],
            }
        }

        case NotificationsActions.NEW_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [...action.payload, ...state.notifications],
            }
        }

        case NotificationsActions.MARK_NOTIFICATIONS_READ: {
            const readNotificationIds = action.payload
            const readNotificationIdsMap = readNotificationIds.reduce(
                (acc: Record<string, boolean>, cur: string) => {
                    acc[cur] = true
                    return acc
                },
                {}
            )
            const notificationsMapped = state.notifications.map((notis) => {
                if (readNotificationIdsMap[notis.notification_id]) {
                    return { ...notis, status: 'read' } as INotification
                }
                return notis
            })
            return {
                ...state,
                notifications: notificationsMapped,
            }
        }

        default: {
            return state
        }
    }
}
