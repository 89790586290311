export enum ModelTypes {
  BLOCK = 'block',
  BLOCK_ARRAY = 'block_array',
  BLOCK_DICTIONARY = 'block_dictionary',
  MT_CONNECTION = 'mt_connection',
  DB_CONNECTION = 'db_connection',
  MAIL_SENDER = 'mail_sender',
  STRING = 'string',
  INTEGER = 'integer',
  SECRET = 'secret',
  FLAG = 'flag',
  BOOL = 'bool',
  ENUM = 'enum',
  NUMBER = 'number',
  DATETIME = 'datetime',
  TIME = 'time',
  DURATION = 'duration',
  DOUBLE = 'double',
  VERSION = 'version',
  TEXT = 'text'
}

export interface IInputSchema {
  type: string
  is_required: boolean
  name?: string
  is_empty_as_null?: boolean
  is_ignore_case?: boolean
  description?: string
  default?: string
  is_access_restricted?: boolean
}

export interface IStringInputSchema extends IInputSchema {
  min_size?: number | string
  max_size?: number | string
  is_email?: boolean
}

export interface INumberInputSchema extends IInputSchema {
  greater_than?: number | string
  greater_or_equal?: number | string
  less_or_equal?: number | string
  less_than?: number | string
  precision?: number
  format?: number | string
}
