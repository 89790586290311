import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons/faXmarkCircle'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'

export enum LogsActions {
    DOWNLOAD_PRODUCT_LOGS_SUCCESS = 'DOWNLOAD_PRODUCT_LOGS_SUCCESS',
    FETCH_PRODUCT_LOGS_LIST_SUCCESS = 'FETCH_PRODUCT_LOGS_LIST_SUCCESS',
    DOWNLOAD_SERVER_LOGS = 'DOWNLOAD_SERVER_LOGS',
    DOWNLOAD_SERVER_LOGS_SUCCESS = 'DOWNLOAD_SERVER_LOGS_SUCCESS',
    DOWNLOAD_SERVER_LOGS_ERROR = 'DOWNLOAD_SERVER_LOGS_ERROR',
    FETCH_PRODUCT_LIVE_LOGS_SUCCESS = 'FETCH_PRODUCT_LIVE_LOGS_SUCCESS',
    NEW_LIVE_LOGS = 'NEW_LIVE_LOGS',
}

type LiveLogLevel = 'trc' | 'dbg' | 'inf' | 'wrn' | 'err' | 'crt'

export const LEVELS: Record<LiveLogLevel, any> = {
    trc: 'TRACE',
    dbg: 'DEBUG',
    inf: 'INFO',
    wrn: <><FontAwesomeIcon style={{ color: '#FFCC00'}} icon={faTriangleExclamation} /> WARNING</>,
    err: <><FontAwesomeIcon style={{ color: '#E15858'}} icon={faXmarkCircle} /> ERROR</>,
    crt: <><FontAwesomeIcon style={{ color: '#E15858'}} icon={faCircleExclamation} /> CRITICAL</>,
} as const

export interface ILiveLog {
    product_id: string,
    log_time: string,
    level: LiveLogLevel,
    tag: string,
    thread_id: string,
    message: string,
    parameters: string[]
}

export interface ILogFile {
    from: string
    to: string
    log_id: string
    product_id?: string
    status?: string
    error?: string
    issue_time?: string | number
}

export interface ILogsReducer {
    productsLiveLogs: Record<string, ILiveLog[]>
    productLogs: ILogFile[]
    isLoading: boolean
}
