import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import styles from './ActionsBar.module.scss'
import { ActionTab } from './ActionTab/ActionTab'
import { ActionsHistory } from './ActionsHistory/ActionsHistory'
import { ActionParameters } from './ActionParameters/ActionParameters'
import { fetchProductActionsHistory } from '../../store/actions/actionsActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { getRemountKeys } from './utils/getRemountKeys'

interface ActionsBarProps {
    guid: string
    className?: string
}

export const ActionsBar = (props: ActionsBarProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    const productActions =
        useTypedSelector((store) => store.actions.productsActions[guid]) ?? {}
    const productActionsNames = Object.keys(productActions)

    const [remountKeys, setRemountKeys] = useState<Record<string, number>>(() =>
        getRemountKeys(productActionsNames)
    )

    const [activeActionName, setActiveActionName] =
        useState(productActionsNames[0]) ?? ''

    const handleActionTabClick = (actionName: string) => {
        setActiveActionName(actionName)
    }

    const resetComponentRemountKey = (actionName: string, newKey: number) => {
        setRemountKeys((prevKeys) => {
            return { ...prevKeys, [actionName]: newKey }
        })
    }

    useEffect(() => {
        const intervalId = setInterval(
            () => dispatch(fetchProductActionsHistory(guid)),
            30000
        )

        return () => clearInterval(intervalId)
    }, [dispatch, guid])

    return (
        <div className="d-flex">
            <div className={styles.actionsListContainer}>
                <h5 className={styles.headers}>Actions list</h5>
                <div className="d-flex flex-column gap-2 px-4 py-3">
                    {!!productActionsNames.length &&
                        productActionsNames.map((actionName) => (
                            <ActionTab
                                key={actionName}
                                actionName={actionName}
                                guid={guid}
                                activeActionName={activeActionName}
                                onActionTabClick={handleActionTabClick}
                            />
                        ))}
                </div>
            </div>
            <div className={styles.customActionContainer}>
                <h5 className={styles.headers}>Custom action</h5>
                <div className="d-flex flex-column gap-3 px-4 py-3">
                    {!!productActionsNames.length &&
                        productActionsNames.map((actionName) => (
                            <ActionParameters
                                key={
                                    actionName + guid + remountKeys[actionName]
                                }
                                actionName={actionName}
                                activeActionName={activeActionName}
                                guid={guid}
                                onReset={resetComponentRemountKey}
                            />
                        ))}
                    <ActionsHistory guid={guid} />
                </div>
            </div>
        </div>
    )
}
