import { Dispatch } from 'redux'
import axios from 'axios'
import { getHeaders } from './http'
import { alertErrorMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { ActionsActionTypes } from '../../types/actionsTypes'
import fileDownload from 'js-file-download'
import { getContentDispositionFilename } from '../../utils/getContentDispositionFilename'

const getProductActionsUrl = (productId: string) =>
    `/api/${apiVersion}/products/${productId}/actions`

export const fetchProductActions = (guid: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(getProductActionsUrl(guid), {
                headers: getHeaders(),
            })
            dispatch({
                type: ActionsActionTypes.GET_ACTIONS_SUCCESS,
                payload: { guid, actions: data },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const fetchProductActionsHistory = (guid: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(`/api/${apiVersion}/actions`, {
                headers: getHeaders(),
                params: {
                    product_id: guid,
                },
            })
            dispatch({
                type: ActionsActionTypes.GET_ACTIONS_HISTORY,
                payload: { guid, productActionsHistory: data },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const postAction = (
    product_id: string,
    action_name: string,
    action_params: Record<string, unknown>,
    client_payload?: Record<string, unknown>
): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.post(
                `/api/${apiVersion}/actions`,
                {
                    product_id,
                    action_name,
                    action_params,
                    client_payload,
                },
                {
                    headers: getHeaders(),
                }
            )
            dispatch(fetchProductActionsHistory(product_id))
        } catch (e: any) {
            alertErrorMessage(e)
            dispatch(fetchProductActionsHistory(product_id))
        }
    }
}

export const downloadActionResult = (actionId: string): any => {
    return async () => {
        try {
            const response = await axios.get(
                `/api/${apiVersion}/actions/${actionId}/result`,
                {
                    headers: getHeaders(),
                    responseType: 'blob'
                }
            )

            if (response.status === 200) {
                const filename = getContentDispositionFilename(response)
                fileDownload(response.data, filename)
            }
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}
