import React, { FC } from 'react'
import DeleteProductsGroupModal from './DeleteProductsGroupModal'
import { CreateProductsGroupsModal } from './CreateProductsGroupsModal/CreateProductsGroupsModal'
import ErrorModal from './ErrorModal'
import InformModal from './InformModal'
import LicenceModal from './LicenceModal'
import MainModal from './MainModal'
import { EditGroupModal } from './EditGroupModal/EditGroupModal'
import { useSelector } from 'react-redux'
import { ModalTypes } from '../../types/modals'
import AddNewUserModal from './AddNewUserModal/AddNewUserModal'
import { LogsModal } from './LogsModal/LogsModal'
import { CsvModal } from './CsvModal/CsvModal'
import { NotificationsModal } from './NotificationsModal/NotificationsModal'
import { ConfigsModal } from './ConfigsModal/ConfigsModal'
import { UploadConfigModal } from './UploadConfigModal/UploadConfigModal'

export const Modals: FC = () => {
    const { modal } = useSelector((state: any) => state)

    switch (modal.modalType) {
        case ModalTypes.EDIT_GROUPS_MODAL:
            return <EditGroupModal />

        case ModalTypes.MAIN_MODAL:
            return <MainModal />

        case ModalTypes.ADD_NEW_USER_MODAL:
            return <AddNewUserModal />

        case ModalTypes.DELETE_PRODUCTS_GROUP_MODAL:
            return <DeleteProductsGroupModal />

        case ModalTypes.ERROR_MODAL:
            return <ErrorModal />

        case ModalTypes.INFORM_MODAL:
            return <InformModal />

        case ModalTypes.LICENCE_MODAL:
            return <LicenceModal />

        case ModalTypes.CREATE_PRODUCTS_GROUPS_MODAL:
            return <CreateProductsGroupsModal />

        case ModalTypes.LOGS_MODAL:
            return <LogsModal />

        case ModalTypes.CSV_MODAL:
            return <CsvModal />

        case ModalTypes.NOTIFICATIONS_MODAL:
            return <NotificationsModal />

        case ModalTypes.CONFIGS_MODAL:
            return <ConfigsModal />
        case ModalTypes.UPLOAD_CONFIGS_MODAL:
            return <UploadConfigModal />

        default:
            return <></>
    }
}
