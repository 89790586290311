import { useTypedSelector } from '../../../hooks/useTypedSelector'
import React, { memo, useState } from 'react'
import { inputCall } from '../../models/control'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getValidationSchema } from '../../models/BlockArray/utils/getValidationSchema'
import { buildControlsExt } from '../../inputs/controls'
import { convertToSentenceCase } from '../../../utils/convertToSentenceCase'
import * as yup from 'yup'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { postAction } from '../../../store/actions/actionsActions'
import { formActionParametersData } from '../../../utils/formActionParametersData'
import styles from './ActionParameters.module.scss'

interface ActionParametersProps {
    className?: string
    actionName: string
    activeActionName: string
    guid: string
    onReset: (actionName: string, newKey: number) => void
}

export const ActionParameters = memo(function ActionParameters(
    props: ActionParametersProps
) {
    const { actionName, activeActionName, guid, onReset } = props
    const dispatch = useAppDispatch()

    const [preventStart, setPreventStart] = useState(false)
    const productActions =
        useTypedSelector((store) => store.actions.productsActions[guid]) ?? {}
    const currentAction = productActions[actionName]

    const actionSchema = (currentAction?.request_schema?.children as any) ?? {}
    const parametersNames = Object.keys(actionSchema as {}) ?? []
    const hasParameters = !!parametersNames.length

    const validationSchema = getValidationSchema(actionSchema)
    const actionParamsData = formActionParametersData(parametersNames)
    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(actionParamsData, validationSchema)

    const checkSchema = yup.object().shape(validationSchema)
    const isParamsFormValid = checkSchema.isValidSync(inputState)

    const paramsDataArr = parametersNames.map((paramName) => {
        const type = actionSchema[paramName]?.type
        const isBool = type === 'flag' || type === 'bool'
        const className = isBool ? 'mr-auto justify-content-start' : ''

        return inputCall({
            type,
            className,
            name: paramName,
            isLabelHidden: false,
            data: {
                label: convertToSentenceCase(paramName ?? ''),
                isColumn: true,
                tooltipText: actionSchema[paramName]?.description,
            },
            schema: actionSchema[paramName],
            state: inputState,
            isFeedbackFixed: true,
            placeholder: actionSchema[paramName]?.default,
        })
    })

    const actionParams = buildControlsExt(
        paramsDataArr,
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    const touchedInputs = Object.keys(touched)
    const isResetDisabled = !touchedInputs.length

    const handleStart = () => {
        dispatch(postAction(guid, actionName, inputState))
        setPreventStart(true)
        setTimeout(() => setPreventStart(false), 3000)
    }

    const handleReset = () => {
        const componentRemountKey = Math.random()
        onReset(actionName, componentRemountKey)
    }

    const isActive = actionName === activeActionName

    if (!isActive) return <></>

    return (
        <div>
            <h5>Parameters</h5>
            {hasParameters ? (
                <>
                    <div className="d-flex flex-column gap-2">
                        {actionParams.map((el) => (
                            <div key={el?.key}>{el}</div>
                        ))}
                    </div>
                    <div className="d-flex gap-2">
                        <button
                            onClick={handleReset}
                            disabled={isResetDisabled}
                            className="my-3 app-btn-main app-btn-apply"
                        >
                            Reset
                        </button>
                        <button
                            onClick={handleStart}
                            disabled={preventStart || !isParamsFormValid}
                            className="my-3 app-btn-main app-btn-reset"
                        >
                            Start
                        </button>
                    </div>
                </>
            ) : (
                <div className={styles.noParams}>No parameters</div>
            )}
        </div>
    )
})
