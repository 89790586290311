export const getNumberSchema = (inputString: any, schema: any, stateValue: string) => {
    inputString = inputString.matches(/^(-)?\d+(\.\d+)?$/, 'Invalid number provided')
        .test('isValidNumber', 'Number is invalid', (val: string) => {
            return !isNaN(parseFloat(val));
        })

    if (stateValue && String(stateValue).split('.')?.[1]?.length > 8) {
        inputString = inputString.test('maxFractionalDigits', 'Max 8 fractional digits', (val: string) => {
            return String(val).split('.')?.[1]?.length < 9
        })
    }
  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test(
      'greaterOrEqual',
      'Must be greater or equal',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) >= BigInt(schema.greater_or_equal)
        }
        return val >= schema.greater_or_equal
      }
    )
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test(
      'greaterThan',
      'Must be greater than',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) > BigInt(schema.greater_than)
        }
        return val > schema.greater_than
      }
    )
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test(
      'lessOrEqual',
      'Must be less or equal',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) <= BigInt(schema.less_or_equal)
        }
        return val <= schema.less_or_equal
      }
    )
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('lessThan', 'Must be less than', (val: any) => {
      if (!schema.is_required && (val === 'null' || val === null)) {
        return true
      }
      if (val && val !== 'null' && Number.isInteger(val)) {
        return BigInt(val) < BigInt(schema.less_than)
      }
      return val < schema.less_than
    })
  }
  if (schema.is_required) {
    inputString = inputString.required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}
